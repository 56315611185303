import React from "react";
import Layout from "@layouts/Layout";

import BlogPostTemplate from "./BlogPost";

const BlogPostPage = ({
  data: { markdownRemark: data, categoriesPosts, authors, navbar },
}) => {
  const dataCategoriesPosts = categoriesPosts?.edges
    .map(item => item?.node)
    .filter(item => item.id !== data.id);

  const authorsData = authors?.edges?.map(item => item?.node);

  const author = authorsData?.find(
    item => item?.frontmatter?.id === data?.frontmatter?.author,
  );

  const seo = {
    ...data?.frontmatter?.seo,
  };

  const navbarData = navbar?.frontmatter;

  return (
    <Layout navbar={navbarData} seo={seo}>
      <BlogPostTemplate
        data={{
          post: data,
          categoriesPosts: dataCategoriesPosts,
          author,
        }}
      />
    </Layout>
  );
};

export default BlogPostPage;

export const query = graphql`
  query BlogPostByID($id: String!, $category: String) {
    navbar: markdownRemark(
      frontmatter: { templateKey: { eq: "NavbarComponent" } }
    ) {
      frontmatter {
        phone
        Items {
          name
          link
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      html
      rawMarkdownBody
      timeToRead
      fields {
        slug
      }
      frontmatter {
        seo {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        date(formatString: "MMMM DD, YYYY")
        title
        description
        author
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 1110, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    categoriesPosts: allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: { eq: "BlogPost" }
          category: { eq: $category }
        }
      }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            templateKey
            date(formatString: "MMMM DD, YYYY")
            title
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 1110, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    authors: allMarkdownRemark(
      filter: {
        frontmatter: { templateKey: { eq: "AuthorsConfig" } }
      }
    ) {
      edges {
        node {
          id
          frontmatter {
            templateKey
            id
            fullname
            role
            image {
              childImageSharp {
                fluid(maxWidth: 560, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
