import React from "react";

function Icon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
      className={className}
    >
      <path
        fill="#1E1B18"
        d="M32 16c0-8.835-7.165-16-16-16S0 7.165 0 16s7.165 16 16 16a.283.283 0 00.281-.285V21.26c0-.95-.77-1.719-1.718-1.719-.95 0-1.72-.77-1.72-1.719v-.568c0-.95.77-1.72 1.72-1.72.949 0 1.718-.769 1.718-1.718v-1.23c0-3.418 2.087-5.279 5.137-5.279.507 0 .99.013 1.419.032.954.043 1.663.84 1.663 1.794a1.905 1.905 0 01-1.905 1.905h-.198c-1.66 0-1.98.788-1.98 1.945v.696c0 1.025.83 1.856 1.856 1.856 1.12 0 1.984.983 1.84 2.094l-.04.311a1.836 1.836 0 01-1.82 1.601 1.836 1.836 0 00-1.837 1.836v3.253c0 3.382 3.328 5.775 5.91 3.59A15.965 15.965 0 0032 16z"
      ></path>
    </svg>
  );
}

export default Icon;
