import React from "react";
import Header from "@layouts/Header";
import Image from "@components/Image";
import Post from "@components/Post";
import Author from "./components/Author";
import { HTMLContent } from "@components/Content";

import FacebookIcon from "@components/svg/FacebookIcon";
import LinkedinIcon from "@components/svg/LinkedinIcon";
import TwitterIcon from "@components/svg/TwitterIcon";

import "./BlogPost.styles.scss";

const BlogPostTemplate = ({
  data: {
    post: {
      html,
      frontmatter: { featuredimage, date, title, video },
    },
    categoriesPosts,
    author,
  },
}) => {
  return (
    <div className="blog-post">
      <div className="blog-post__wrapper container">
        <div className="blog-post__image">
          <Image data={featuredimage} />
        </div>
        <div className="blog-post__content-container">
          <div className="blog-post__header">
            {author && (
              <Author data={{ ...(author.frontmatter || {}) }} />
            )}
            <div className="blog-post__date">{date}</div>
          </div>
          <div className="blog-post__content">
            <HTMLContent content={html} />
            <div className="blog-post__share">
              <div className="blog-post__share__title">
                Share this story
              </div>
              <div className="blog-post__share__items">
                <a>
                  <FacebookIcon />
                </a>
                <a>
                  <LinkedinIcon />
                </a>
                <a>
                  <TwitterIcon />
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* {categoriesPosts?.length > 0 && (
          <div className="blog-post__related-posts">
            <h3 className="blog-post__related-posts__title">
              Related issue
            </h3>
            <div className="blog-post__related-posts__items">
              {categoriesPosts?.map(item => {
                return (
                  <Post
                    slug={item?.fields?.slug}
                    {...item.frontmatter}
                  />
                );
              })}
            </div>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default BlogPostTemplate;
